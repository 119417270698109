
import { defineComponent, onMounted } from 'vue';
import Form from '@/view/pages/common/news/Form.vue';

export default defineComponent({
  name: 'news-edit',
  components: {
    Form,
  },
  setup() {
    return {};
  },
});
